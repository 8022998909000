import styled from '@emotion/styled'
import clsx from 'clsx'

export interface BgBoxProps {
  src: string
  mark?: boolean
  className?: string
  markPosition?: 'center' | 'top' | 'bottom'
}

const BgImgComponent = styled.div<BgBoxProps>(
  ({ src, mark, markPosition = 'center' }) => `
  ${mark ? `mask: url(${src});` : `background-image: url(${src});`}
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: ${markPosition};
`
)

export const BgImg = ({ className, ...props }: BgBoxProps) => (
  <BgImgComponent className={clsx('bg-center bg-no-repeat', className)} {...props} />
)
