import 'react-bnb-gallery/dist/style.css'

import { useCallback, useEffect, useState } from 'react'

import clsx from 'clsx'
import type { Props } from 'react-bnb-gallery'
import BnbGallery from 'react-bnb-gallery'

interface GalleryProps extends Props {
  nextPhoto?: (number: number) => void
  prevPhoto?: (number: number) => void
}

export const Gallery = (props: GalleryProps) => {
  const { activePhotoIndex, photos, show, nextPhoto, prevPhoto } = props
  const [index, setIndex] = useState(activePhotoIndex || 0)
  const SIZE = photos?.length
  const onNextPhoto = useCallback(() => {
    const indexPhoto = (index + 1) % SIZE
    setIndex(indexPhoto)
    nextPhoto?.(indexPhoto)
  }, [SIZE, index, nextPhoto])
  const onPrevPhoto = useCallback(() => {
    const indexPhoto = (SIZE + index - 1) % SIZE
    setIndex(indexPhoto)
    prevPhoto?.(indexPhoto)
  }, [SIZE, index, prevPhoto])

  useEffect(() => setIndex(activePhotoIndex || 0), [activePhotoIndex])

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event

      if (key === 'ArrowRight') onNextPhoto()
      if (key === 'ArrowLeft') onPrevPhoto()
    },
    [onPrevPhoto, onNextPhoto]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <div className="">
      <BnbGallery
        key={activePhotoIndex}
        {...props}
        activePhotoIndex={(activePhotoIndex || 0) >= 0 ? activePhotoIndex : 0}
        prevButtonPressed={onPrevPhoto}
        nextButtonPressed={onNextPhoto}
        activePhotoPressed={onNextPhoto}
        opacity={0.5}
      />
      <div
        className={clsx(
          'fixed bottom-4 left-0 z-[2001] flex w-full justify-center',
          !show && 'hidden'
        )}
      >
        <div className="rounded-full bg-white px-4 py-1">
          {index + 1} / {SIZE}
        </div>
      </div>
    </div>
  )
}
