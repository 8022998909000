import React from 'react'

import NextImage from 'next/image'

interface Props {
  name: string
  imageUrl: string
  imageAlt: string
  variationName: string
  quantity: number
  totalPrice: string
  onAdjustQuantity: (num: number) => void
}

export const ProductCardWithAdjustQuantity = ({
  name,
  imageUrl,
  imageAlt,
  quantity,
  variationName,
  totalPrice,
  onAdjustQuantity
}: Props) => (
  <div className="grid grid-cols-[7fr_10fr] gap-x-4 text-colorSeven">
    <div>
      <NextImage
        src={imageUrl ?? '/assets/images/product-image-placeholder.svg'}
        alt={imageAlt ?? 'Placeholder'}
        height={640}
        width={640}
        priority
        className="h-full w-full rounded-md object-cover object-center"
      />
    </div>
    <section>
      <p className="p-0 font-bold text-colorFourteen">{name}</p>

      <section className="grid">
        <div className="flex items-center justify-between">
          <p className="p-0 py-3 text-base">{variationName}</p>
        </div>
        <div className="flex  text-colorFourteen">
          <button
            type="button"
            onClick={() => {
              if (quantity > 0) onAdjustQuantity(quantity - 1)
            }}
            className="rounded-md py-3 pr-4 text-center text-black"
          >
            -
          </button>
          <span className="rounded-lg bg-white px-6 py-3 text-lg font-semibold text-colorFourteen">
            {quantity}
          </span>
          <button
            type="button"
            onClick={async () => {
              onAdjustQuantity(quantity + 1)
            }}
            className="rounded-md px-4 py-3 text-center text-black"
          >
            +
          </button>
        </div>
        <div className="flex justify-between pt-4 text-base">
          <button
            type="button"
            className="underline"
            onClick={async () => {
              onAdjustQuantity(0)
            }}
          >
            Remove
          </button>
          <p className="p-0 text-lg font-semibold text-colorFourteen">{totalPrice}</p>
        </div>
      </section>
    </section>
  </div>
)
