import type { ReactNode } from 'react';

import { Layout } from '~/components/common';

import { Wrapper } from './wrapper';

interface PageContainerProps {
  children: ReactNode;
}

export const PageContainer = (props: PageContainerProps) => {
  const { children } = props;
  return (
    <Layout>
      <div className="min-h-inherit w-full bg-colorFifteen">
        <Wrapper className="container  m-auto max-w-5xl pt-5 pb-8">{children}</Wrapper>
      </div>
    </Layout>
  );
};
