/* eslint-disable global-require */
import React from 'react';

import { BgImg } from '../BgImg';

export type IconType = 'arrowRight' | 'xClose' | 'creditCard' | 'curve' | 'arrowDown';

interface IconProps {
  icon: IconType;
  className?: string;
  mark?: boolean;
  markPosition?: 'center' | 'top' | 'bottom';
}

type IconsResourceProps = {
  [key in IconType]: any;
};

const icons: IconsResourceProps = {
  arrowRight: require('./assets/arrow-right.svg'),
  xClose: require('./assets/x-close.svg'),
  creditCard: require('./assets/credit-card.svg'),
  curve: require('./assets/curve.svg'),
  arrowDown: require('./assets/arrow_down.svg')
};

export const SvgIcon = ({ icon, className = 'w-3.5 h-3.5', mark, markPosition }: IconProps) => (
  <BgImg markPosition={markPosition} src={icons[icon]} mark={mark} className={className} />
);
